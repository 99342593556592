@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'base/variable.scss';
@import 'base/font.scss';
@import 'component/tooltip.scss';
@import 'react-loading-skeleton/dist/skeleton.css';
// @import '/font/iconfont.css';

:root {
  --color-text: #000000;
  --color-desc-text: #9ca0b2;
  --color-text-primary: #335fff;
  --color-text-secondary: #6a7394;
  --color-text-danger: #e40000;
  --color-text-green: #00e89b;
  --color-text-other: #ffffff;
  --color-text-primary-hover: #5121f8;
  --color-text-danger-hover: #a12823;
  --color-btn-other: #ffffff;
  // bg
  --color-box-bg: #ffffff;
  --color-bg: #f7f7fa;
  --color-btn-bg: #f4f4f4;
  --color-select-bg: #f5f7ff;
  --color-icon-bg: #d7e2f5;
  --color-opposite-bg: #696969;
  --color-table-nest-bg: #fbfbfb;
  // border
  --color-border: #e0e3ea;
  --color-border-primary: #335fff;
  --color-border-danger: #2f5bf5;
  --color-card-border: #e5e6eb;
}

.dark {
  --color-text: #ffffff;
  --color-desc-text: #c6c5c5;
  // bg
  --color-box-bg: #1f1f1f;
  --color-bg: #000000;
  --color-btn-bg: #1f1f1f;
  --color-select-bg: #ffffff;
  --color-icon-bg: #ffffff;
  --color-opposite-bg: #ffffff;
  // border
  --color-border: #ffffff;
}

html {
  font-size: 16px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: inter-regular, $fontfallback;
  width: 100%;
}

body {
  @apply bg-base;
}

.ui-center {
  @apply flex items-center justify-center;
}

a {
  color: inherit;
  text-decoration: none;
}

div,
span,
li,
ul,
section,
input {
  box-sizing: border-box;
  @apply text-base;
}

// contianer
$containerWidth: 1362;

.container-w {
  width: #{$containerWidth}px;
}

.container-min-w {
  min-width: #{$containerWidth}px;
}

// icon
.diox-font {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}

.full-icon {
  @apply h-[32px] w-[32px] rounded-full bg-icon flex items-center justify-center text-primary;
}

// scroll
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 6px;
}

*:hover> ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

/* block icon th */
.ex-icon-th {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  .icon {
    @apply absolute text-[16px] left-[0] top-[calc(50%-8px)] text-desc;
  }

  .text {
    @apply pl-[20px] text-[13px] text-desc;
  }
}

.line-clamp {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ex-table-thead {
  cursor: default;
}